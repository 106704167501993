import CheckIcon from "@heroicons/react/24/outline/CheckIcon"
import { Link } from "gatsby"
import React from "react"

type LessonProp = {
  id: string
  title: string
  finished: boolean
  description: string
  url: string
}

function LessonsContainer({ children }) {
  return (
    <div className="overflow-hidden bg-white border border-slate-200 sm:rounded-md">
      <ul role="list" className="divide-y divide-slate-200  ">
        {children}
      </ul>
    </div>
  )
}

const Lesson: React.FC<LessonProp> = (props) => {
  return (
    <li>
      <Link
        to={props.url}
        className={`flex ${
          props.finished
            ? "bg-slate-50 hover:bg-slate-100"
            : "bg-white   hover:bg-slate-50"
        }`}
      >
        <div className="px-4 pt-4">
          <div
            className={`flex items-center justify-center w-8 h-8 ${
              props.finished
                ? "text-green-700 bg-green-100"
                : "bg-slate-100 text-slate-500"
            } rounded-full`}
          >
            <CheckIcon className="w-5 h-5" />
          </div>
        </div>
        <div className="py-4 pr-4 sm:pr-6">
          <div className="flex items-center justify-between">
            <p
              className={`text-lg font-medium font-display ${
                props.finished ? "text-slate-600" : "text-slate-800  "
              }`}
            >
              {props.title}
            </p>
          </div>
          {!props.finished && (
            <div className="sm:flex sm:justify-between">
              <div className="sm:flex">
                <p className="flex items-center text-slate-500  ">
                  {props.description}
                </p>
              </div>
            </div>
          )}
        </div>
      </Link>
    </li>
  )
}

export const LessonsPicker: React.FC<{ lessons: LessonProp[] }> = ({
  lessons,
}) => (
  <LessonsContainer>
    {lessons.map((lesson) => (
      <Lesson key={lesson.id} {...lesson} finished={false} />
    ))}
  </LessonsContainer>
)

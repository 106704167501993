import { Link } from "gatsby"
import React from "react"

export const Breadcrumb: React.FC<{
  items: Array<{ label: string; url?: string }>
}> = ({ items }) => (
  <ol className="flex flex-wrap items-center font-display">
    {items.map(({ label, url }, index, array) => (
      <React.Fragment key={label}>
        {url && index < array.length - 1 && (
          <li className="breadcrumb__separator">
            <Link
              to={url}
              className="text-slate-900 cursor-pointer hover:underline  "
            >
              {label}
            </Link>
          </li>
        )}

        {(!url || index === array.length - 1) && (
          <li className="text-slate-400  ">{label}</li>
        )}
      </React.Fragment>
    ))}
  </ol>
)

import React from "react"
import { PageProps } from "gatsby"
import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import { Seo } from "../components/Seo/Seo"

import { Footer } from "../components/Footer/Footer"
import { DarkMode } from "../components/DarkMode/DarkMode"
import { Navbar } from "../components/Navbar/Navbar"
import { Breadcrumb } from "../components/Breadcrumb/Breadcrumb"
import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"

import lessons from "../i18n/urls/lessons.json"
import { Drawer } from "../components/Drawer/Drawer"
import {
  getLessonUrl,
  getCourseUrl,
  navigateToHome,
} from "../i18n/utils/getUrl"

import { Lesson as Course } from "../components/Lessons/Lesson/Lesson"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { LessonsPicker } from "../components/LessonsPicker/LessonsPicker"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { BackgroundShapes } from "../components/BackgroundShapes"
import { MobileNavbar } from "../components/MobileNavbar/MobileNavbar"

type Props = PageProps<
  {
    mdx: {
      id: string
      body: string
      frontmatter: {
        created_at: string
        lang: string
        level: string
        order: number
        seo_description: string
        seo_title: string
        title: string
        updated_at: string
        breadcrumb_title: string
      }
    }
    courses: {
      edges: {
        node: {
          id: string
          frontmatter: {
            title: string
            description: string
            level: string
            order: number
            illustration: {
              childImageSharp: IGatsbyImageData
            }
          }
        }
      }[]
    }
    lessons: {
      edges: {
        node: {
          id: string
          frontmatter: {
            id: string
            title: string
            description: string
            url: string
            level: string
            lang: string
          }
        }
      }[]
    }
  },
  {
    langKey: string
    level: string
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

const CoursesPicker: React.FC<Props> = (props) => {
  const intl = useIntl()

  const { langKey } = props.pageContext

  const breadcrumb = [
    {
      url: navigateToHome(langKey),
      label: intl.formatMessage({ id: "lessons.breadcrumb.home" }),
    },
    { label: intl.formatMessage({ id: "lessons.breadcrumb.label" }) },
  ]

  return (
    <GlobalWrapper>
      <Seo
        title={props.data.mdx.frontmatter.seo_title}
        description={props.data.mdx.frontmatter.seo_description}
        lang={props.pageContext.langKey}
        langUrls={props.pageContext.canonicals}
      />

      <SelectLanguageModal languages={props.pageContext.canonicals} />

      <div className="bg-white relative">
        <BackgroundShapes step={0} absolute />

        <div className="relative overflow-hidden">
          <Navbar langKey={props.pageContext.langKey} />
        </div>

        <div className="py-4 relative">
          <div className="px-4">
            <div className="max-w-3xl mx-auto">
              <Breadcrumb items={breadcrumb} />
            </div>
            <h1 className="max-w-3xl mx-auto mt-2 text-4xl font-display font-bold text-slate-900  ">
              {props.data.mdx.frontmatter.title}
            </h1>
            <p className="max-w-3xl mx-auto mt-2 text-slate-700  ">
              {props.data.mdx.frontmatter.seo_description}
            </p>
          </div>

          <div className="max-w-3xl mx-auto mt-8 pb-14">
            <LessonsPicker
              lessons={props.data.lessons.edges.map(({ node }) => ({
                id: node.id,
                url: getLessonUrl({
                  lang: node.frontmatter.lang,
                  level: node.frontmatter.level,
                  url: node.frontmatter.id,
                }),
                title: node.frontmatter.title,
                description: node.frontmatter.description,
                finished: false,
              }))}
            />
          </div>
        </div>

        <MobileNavbar langKey={props.pageContext.langKey} />
        <Footer lang={props.pageContext.langKey} />
        <Drawer langKey={props.pageContext.langKey} />
      </div>
    </GlobalWrapper>
  )
}

export default CoursesPicker

export const query = graphql`
  query CoursesPickerQuery($level: String, $langKey: String) {
    mdx(
      frontmatter: {
        lang: { eq: $langKey }
        level: { eq: $level }
        category: { eq: "course" }
      }
    ) {
      id
      body
      frontmatter {
        created_at
        lang
        level
        category
        order
        title
        breadcrumb_title
        seo_description
        description
        seo_title
        title
        updated_at
      }
    }

    lessons: allMdx(
      filter: {
        frontmatter: { lang: { eq: $langKey }, category: { eq: "lesson" } }
      }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            id
            title
            description
            url
            level
            lang
          }
        }
      }
    }
  }
`

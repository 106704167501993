import React from "react"

export const Square: React.FC<{
  position: string
  size: string
  translate?: string
  color?: string
}> = ({ position, size, translate, color = false }) => (
  <div className={`transform duration-500 ease-in-out ${translate}`}>
    <div
      className={`absolute ${position}`}
      style={{
        animationName: "bounce",
        animationDuration: "10s",
        animationIterationCount: "infinite",
        animationTimingFunction: "ease-in-out",
      }}
    >
      <div
        className={`${size} border-4 rounded-xl ${
          color ? color : "border-slate-200"
        }`}
        style={{
          animationName: "spin",
          animationDuration: "60s",
          animationIterationCount: "infinite",
          animationTimingFunction: "linear",
        }}
      ></div>
    </div>
  </div>
)

export const Circle: React.FC<{
  position: string
  size: string
  translate?: string
  color?: string
}> = ({ position, size, translate, color = false }) => (
  <div className={`transform duration-500 ease-in-out ${translate}`}>
    <div
      className={`absolute ${position} ${size} rounded-full border-4 ${
        color ? color : "border-slate-200  "
      }`}
      style={{
        animationName: "bounce",
        animationDuration: "20s",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
      }}
    ></div>
  </div>
)

export const Triangle: React.FC<{
  position: string
  size: string
  translate?: string
  color?: string
}> = ({ position, size, translate, color = false }) => (
  <div className={`transform duration-500 ease-in-out ${translate}`}>
    <div
      className={`absolute ${position}`}
      style={{
        animationName: "bounce",
        animationDuration: "5s",
        animationIterationCount: "infinite",
        animationTimingFunction: "ease-in-out",
      }}
    >
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        className={`${size} stroke-current`}
        style={{
          animationName: "spin",
          animationDuration: "60s",
          animationIterationCount: "infinite",
          animationTimingFunction: "linear",
        }}
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            transform="translate(-224.000000, -478.000000)"
            className={`${color ? color : "text-slate-200"} stroke-current`}
          >
            <path d="M235.636197,484.432088 C236.008618,484.338982 236.416558,484.387951 236.771744,484.601062 C236.982868,484.727737 237.159565,484.904433 237.286239,485.115558 L237.286239,485.115558 L245.753859,499.228256 C245.96697,499.583442 246.015938,499.991382 245.922833,500.363803 C245.829728,500.736225 245.594548,501.073128 245.239363,501.286239 C245.00624,501.426113 244.739485,501.5 244.467619,501.5 L244.467619,501.5 L227.532381,501.5 C227.118167,501.5 226.743167,501.332107 226.471721,501.06066 C226.200274,500.789214 226.032381,500.414214 226.032381,500 C226.032381,499.728134 226.106268,499.461379 226.246141,499.228256 L226.246141,499.228256 L234.713761,485.115558 C234.926872,484.760372 235.263775,484.525193 235.636197,484.432088 Z"></path>
          </g>
        </g>
      </svg>
    </div>
  </div>
)

export type Props = {
  step: number
  error?: boolean
  absolute?: boolean
}

export const BackgroundShapes: React.FC<Props> = (props) => {
  const isPair = props.step % 2 === 0

  return (
    <div
      className={`${
        props.absolute ? "absolute" : "fixed"
      } top-0 bottom-0 left-0 right-0 hidden lg:block`}
    >
      <Square
        position="right-2 top-20"
        color="border-pink-200"
        size="w-44 h-44"
        translate={`${
          !props.error
            ? isPair && "-translate-x-20 translate-y-10"
            : "translate-x-full"
        } `}
      />

      <Square
        position="left-32 top-96"
        color="border-emerald-200"
        size="w-24 h-24"
        translate={`${
          !props.error
            ? isPair && "-translate-x-5 translate-y-20"
            : "-translate-x-full"
        } `}
      />

      <Circle
        position="-left-6 top-32"
        size="w-60 h-60"
        color="border-blue-200"
        translate={`${
          !props.error
            ? isPair && "-translate-x-20 translate-y-10"
            : "-translate-x-full"
        } `}
      />

      <Circle
        position="right-6 top-96"
        size="w-32 h-32"
        color="border-blue-200"
        translate={`${
          !props.error
            ? isPair && "-translate-x-5 translate-y-20"
            : "translate-x-full"
        } `}
      />
    </div>
  )
}
